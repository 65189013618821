export const addressProps = {
  street_number: {
    type: 'string',
    title: 'Straße und Hausnummer'
  },
  zip: {
    type: 'string',
    title: 'PLZ'
  },
  city: {
    type: 'string',
    title: 'Ort'
  }
};

export const addressRequired = [
  'street_number',
  'zip',
  'city'
];
