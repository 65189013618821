import { delay, isString } from 'lodash';
import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import LoginForm from '../component/LoginForm';
import { APP_ROUTES } from '../constants';
import { getToken, setUserSession } from '../lib/auth';
import { isValidRedirect } from '../lib/url';
import { sendAuth } from '../services/api/app';

const LoginPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get('token');
  const redirectTo = queryParams.get('redirectTo');

  const [ magicToken, setMagicToken ] = useState<boolean | null | string>();
  const [ showForm, setShowForm ] = useState<boolean>(false);
  const [ message, setMessage ] = useState<string | undefined>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (value) {
      setMagicToken(value);
    } else {
      setMagicToken(false);

      timer = setTimeout(() => {
        setShowForm(true);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [  value ]);

  useEffect(() => {
    async function checkToken(): Promise<void> {
      if (magicToken && isString(magicToken)) {
        setIsLoading(true);

        const data = await sendAuth({
          token: magicToken
        });

        if (data.token && data.user) {
          setUserSession(data.token, data.user);

          const urlToRedirect = redirectTo && isValidRedirect(redirectTo)
            ? redirectTo : APP_ROUTES.HOME;

          delay(() => window.location.href = urlToRedirect, 100);
        } else {
          setShowForm(true);
          setMessage(data.msg);
        }
      }
    }

    if (!showForm && !isLoading && magicToken) {
      void checkToken();
    }

    return () => {
      // @TODO: cancel token
    };
  }, [ isLoading, magicToken, navigate, redirectTo, showForm ]);

  if (getToken()) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-base-5 flex flex-col gap-4 p-6 w-96">
        <img
          className="max-h-14 w-auto mb-2"
          src="https://preview.simonmade.it/form-hero/sidebar-logo.svg"
          alt="Logo"
        />
        {!showForm && (
          <div className="text-center">Loading</div>
        )}
        {showForm && <LoginForm message={message} />}
      </div>
    </div>
  );
};

export default LoginPage;
