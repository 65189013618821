import { filter, map, size, sortBy, times } from 'lodash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import CardSkeleton from '../component/CardSkeleton';
import ClientCard from '../component/ClientCard';
import Icon from '../component/Icon';
import Modal from '../component/Modal';
import AddUserModal from '../component/modal/AddUserModal';
import { APP_ROUTES, FORM_STATUS } from '../constants';
import { replaceRouteParam } from '../lib/url';
import { getUsersAgency } from '../services/api/app';
import type { TUser } from '../types/user';

const UsersAgencyPage: FC = () => {
  const [ showModalNewClient, setShowModalNewClient ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ reload, setReload ] = useState(true);
  const [ fetechedUsers, setFetchedUsers ] = useState<TUser[]>();
  const [ users, setUsers ] = useState<TUser[]>();
  const [ search, setSearch ] = useState<string>('');

  useEffect(() => {
    const getUsersData = async (): Promise<void> => {
      setIsLoading(true);
      setReload(false);

      const {
        users: fetchedUsers
      } = await getUsersAgency();

      setUsers(fetchedUsers);
      setFetchedUsers(fetchedUsers);
      setIsLoading(false);
    };

    if (reload) {
      void getUsersData();
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [ reload ]);

  useEffect(() => {
    if (search) {
      const filteredUsers = users?.filter(
        user =>
          user.fullname?.toLowerCase()
            .includes(search.toLowerCase())
          || user.lastname?.toLowerCase()
            .includes(search.toLowerCase())
      );

      setUsers(filteredUsers);
    } else {
      setUsers(fetechedUsers);
    }
  }, [ fetechedUsers, search, users ]);

  return (
    <div>
      <div className="dashboard-head">
        <div className="flex gap-4 items-center">
          <div>
            <h1>Mandanten</h1>
          </div>
          <div className="flex gap-4 ml-auto">
            <div className="relative">
              <Icon
                iconName="MagnifyingGlassIcon"
                className="absolute -translate-y-2/4 right-2 top-2/4"
              />
              <input
                type="text"
                placeholder="Nach Namen suchen..."
                className="h-full !pr-8"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <button
              onClick={() => setShowModalNewClient(true)}
              className="btn"
            >
              <Icon iconName="UserPlusIcon" /> Neuer Mandant
            </button>
          </div>
        </div>
      </div>
      <div className="flex-table client-table">
        <div className="thead">
          <div className="tr">
            <div>Name</div>
            <div>Formulare</div>
            <div>Fertiggestellt</div>
          </div>
        </div>
        <div className="tbody">
          {isLoading && (<>
            {times(5, index => (
              <CardSkeleton key={index} />
            ))}
          </>)}
          {!isLoading && (
            <>
              {
                size(users) > 0 ? users?.map(user => {
                  const formsDone = filter(user.submissions, { status_name: FORM_STATUS.CLOSED }).length;
                  const formsStatusAll = sortBy(map(user.submissions, 'status'));

                  return (
                    <Link
                      key={user.email}
                      to={replaceRouteParam(APP_ROUTES.USER, user.uuid)}
                    >
                      <ClientCard
                        key={user.email}
                        title={user.fullname || user.lastname}
                        forms={user.submissions?.length || 0}
                        formsDone={formsDone}
                        status={formsStatusAll[0]}
                      />
                    </Link>
                  );
                }) : (
                  <div className="no-result">Keine Mandanten</div>
                )
              }
            </>
          )}
        </div>
      </div>
      {showModalNewClient && (
        <Modal
          title="Neuer Mandant"
          close={() => {
            setReload(true);
            setShowModalNewClient(false);
          }}
        >
          <AddUserModal
            successFunction={() => {
              setReload(true);
              setShowModalNewClient(false);
            }}
            redirectToUser
          />
        </Modal>
      )}
    </div>
  );
};

export default UsersAgencyPage;
