import type { FC } from 'react';
import React from 'react';

interface IComment {
  author: string;
  text: string;
  created_at: string;
}

const CommentBox: FC<IComment> = ({
  text,
  created_at
}) => {
  return (
    <div className="flex gap-3">
      <div className="whitespace-nowrap w-1/4">
        <strong>{created_at}</strong>
      </div>
      <div className="border-l border-base-10 px-3">
        <div className="whitespace-pre-line">
          {text}
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
