import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';

import { newCommentConfig } from '../../forms/new-comment/config';
import { validateForm } from '../../lib/validation';
import { addComment } from '../../services/api/app';
import FormHelper from '../FormHelper';
import LoadingSpinner from '../LoadingSpinner';

interface IAddCommentModal {
  formId?: string | number | boolean;
  successFunction?: () => void;
}

const AddCommentModal: FC<IAddCommentModal> = ({
  formId,
  successFunction
}) => {
  const newClientForm = newCommentConfig;
  const [ formData, setFormData ] = useState<any>({
    ...newClientForm.initialData
  });
  const [ isLoading, setIsLoading ] = useState(false);
  const [ , setFeedback ] = useState({});
  const {
    isValid
  } = validateForm(newClientForm.schema, formData);

  if (!formId) {
    return null;
  }

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    if (!isLoading) {
      setIsLoading(true);

      const data = await addComment({
        ...formData,
        submission_id: formId
      });

      setFeedback(data);
      // setSuccess(data.ok);
      // setMessage(data.msg || '');
      setIsLoading(false);

      if (data.ok && successFunction) {
        void successFunction();
      }
    }
  };

  return (
    <div>
      <div className="form-wrapper">
        <FormHelper
          schema={newClientForm.schema}
          uischema={newClientForm.uischema}
          formData={formData}
          showValidation={false}
          setFormData={({ data: dataInput }) => setFormData(dataInput)}
        />
      </div>
      <button
        className="btn btn-full"
        onClick={e => void handleSave(e)}
        disabled={isLoading || !isValid}
      >
        {isLoading && (
          <LoadingSpinner />
        )}
        Kommentar Speichern
      </button>
    </div>
  );
};

export default AddCommentModal;
