import classNames from 'classnames';
import type { FC } from 'react';

import { FORM_STATUS_ID } from '../constants';
import { getStatusClass } from '../lib/status';

import Icon from './Icon';

interface IClientCard {
  title: string;
  forms: string | number;
  formsDone: string | number;
  status?: string | number;
}

const ClientCard: FC<IClientCard> = ({
  title = '',
  forms = '',
  formsDone = '',
  status = FORM_STATUS_ID.OPEN
}) => {
  return (
    <div className={classNames(
      'tr status',
      getStatusClass(status))
    }>
      <div>{title}</div>
      <div>{forms}</div>
      <div>{formsDone}</div>
      <div className="icon-warp">
        <Icon iconName="ChevronRightIcon" />
      </div>
    </div>
  );
};

export default ClientCard;
