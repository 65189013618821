import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';

import useFormStore from '../services/store/form';

const DebugContainer: FC = () => {
  const [ isOpen, setIsOpen ] = useState(false);
  const {
    formData
  } = useFormStore(state => state);

  return (
    <div
      className={classNames('debug-container', {
        open: isOpen
      })}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="debug-trigger">
        {isOpen ? '🧲' : '🪲'}
      </div>
      <div className="debug-content">
        <pre>{JSON.stringify(formData, null, 2)}</pre>
      </div>
    </div>
  );
};

export default DebugContainer;
