import { pick } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_ROUTES } from '../../constants';
import { newClientConfig } from '../../forms/new-client/config';
import { replaceRouteParam } from '../../lib/url';
import { validateForm } from '../../lib/validation';
import type { TApiResponse } from '../../services/api/app';
import { saveUser } from '../../services/api/app';
import Alert from '../Alert';
import FormHelper from '../FormHelper';
import LoadingSpinner from '../LoadingSpinner';

interface IAddUserModal {
  user?: any;
  successFunction?: () => void;
  redirectToUser?: boolean;
}

const AddUserModal: FC<IAddUserModal> = ({
  user,
  successFunction,
  redirectToUser = false
}) => {
  const userData = user ? pick(user, [
    'uuid', 'id', 'firstname', 'lastname', 'street_number', 'zip', 'city', 'email', 'phone'
  ]) : undefined;

  const navigate = useNavigate();
  const newClientForm = newClientConfig;
  const [ formData, setFormData ] = useState<any>(userData ? { client: userData } : newClientForm.initialData);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ feedback, setFeedback ] = useState<TApiResponse | undefined>();
  const {
    isValid
  } = validateForm(newClientForm.schema, formData);

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    if (!isLoading) {
      setFeedback(undefined);
      setIsLoading(true);

      const data = await saveUser({
        ...formData.client,
        ...userData ? { uuid: user.uuid } : {}
      });

      setFeedback(data);

      if (data.success) {
        setFormData(newClientForm.initialData);

        if (redirectToUser && data.user_uuid) {
          // eslint-disable-next-line no-console
          void navigate(replaceRouteParam(APP_ROUTES.USER, data.user_uuid));
        } else if (successFunction) {
          void successFunction();
        }
      }
      setIsLoading(false);

    }
  };

  // if (feedback?.ok && feedback.user?.uuid) {
  //   return <Navigate to={replaceRouteParam(APP_ROUTES.USER, feedback.user.uuid)} />;
  // }

  return (
    <div>
      {!isLoading && feedback && (
        <Alert message={feedback.msg} success={feedback.success} />
      )}
      <div className="form-wrapper">
        <FormHelper
          schema={newClientForm.schema}
          uischema={newClientForm.uischema}
          formData={formData}
          showValidation={false}
          setFormData={({ data: dataInput }) => setFormData(dataInput)}
        />
      </div>
      <div className="form-footer">
        <button
          className="btn w-full"
          onClick={e => void handleSave(e)}
          disabled={isLoading || !isValid}
        >
          {isLoading && (
            <LoadingSpinner />
          )}
          Mandant anlegen
        </button>
      </div>
    </div>
  );
};

export default AddUserModal;
