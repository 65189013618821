import { delay } from 'lodash';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { APP_ROUTES, ICON_SIZE } from '../../constants';
import { removeUserSession } from '../../lib/auth';
import useFormStore from '../../services/store/form';
import Icon from '../Icon';

import UserFormSidebar from './UserForm';

interface IUserSidebar {
  children?: React.ReactNode;
  title?: string;
}

const userRoutes: {
  name: string;
  icon: string;
  url?: string;
  action?: () => void;
}[] = [
  {
    name: 'Übersicht',
    icon: 'HomeIcon',
    url: APP_ROUTES.HOME
  },
  {
    name: 'Ausloggen',
    icon: 'ArrowLeftStartOnRectangleIcon',
    action: () => {
      void removeUserSession();
      delay(() => window.location.href = APP_ROUTES.HOME, 100);
    }
  }
];

const UserSidebar: FC<PropsWithChildren<IUserSidebar>> = ({
  title = ''
}) => {
  const {
    formType,
    formSendSuccess
  } = useFormStore(state => state);

  return (
    <>
      {title && (
        <div className="sidebar-title">
          {title}
        </div>
      )}
      <div className="sidebar-navi">
        {userRoutes.map(item => {
          if (item.action) {
            return (
              <button
                key={item.icon + item.name}
                onClick={item.action}
                className="flex gap-2 items-center"
              >
                <Icon iconName={item.icon} size={ICON_SIZE.S} /> {item.name}
              </button>
            );
          } else if (item.url) {
            return (
              <Link
                key={item.url + item.name}
                to={item.url}
                className="flex gap-2 items-center"
              >
                <Icon iconName={item.icon} size={ICON_SIZE.S} /> {item.name}
              </Link>
            );
          }
        })}
      </div>
      {(formType && !formSendSuccess) && (<UserFormSidebar />)}
    </>
  );
};

export default UserSidebar;
