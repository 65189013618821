import type { FC, PropsWithChildren } from 'react';
import React from 'react';

interface ISidebarWithChildren {
  children?: React.ReactNode;
}

const SidebarWithChildren: FC<PropsWithChildren<ISidebarWithChildren>> = ({
  children
}) => {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar">
        <div className="sidebar-header">
          <img src="https://preview.simonmade.it/form-hero/sidebar-logo.svg" alt="Logo" />
        </div>
        <div className="sidebar-content">
          {children}
        </div>
        <div className="sidebar-footer">
          <div>
            <strong>Notarin</strong>
            <div>Katharina Tschernoster, LL.M. oec</div>
          </div>
          <div>
            <div>0123 456 789</div>
            <div>kontakt@tschernoster.de</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarWithChildren;
