import deepmerge from 'deepmerge';
import { size } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import React, { useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import DebugContainer from '../component/DebugContainer';
import FormWizard from '../component/FormWizard';
import Icon from '../component/Icon';
import { APP_ROUTES, ICON_SIZE } from '../constants';
import { erbausschlagungConfig } from '../forms/erbausschlagung/config';
import { getAppConfig, getForm } from '../services/api/app';
import useFormStore from '../services/store/form';
import type { TSubmission } from '../types/user';

const FormUserPage: FC = () => {
  const { uuid } = useParams();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ form, setForm ] = useState<TSubmission>();
  const {
    setNonce,
    setFormData,
    setFormType,
    setFormConfig,
    resetStore,
    formSendSuccess
  } = useFormStore(state => state);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (uuid) {
        const { submission: fetchedForm } = await getForm(uuid);
        const { nonce: fetchedNonce } = await getAppConfig();

        if (fetchedNonce) {
          setNonce(fetchedNonce);
        }

        if (fetchedForm) {
          setForm(fetchedForm);

          if (fetchedForm.form_type) {
            setFormType(fetchedForm.form_type);

            if (fetchedForm.form_type === 'ERA01') {
              setFormConfig(erbausschlagungConfig);
            }
          }

          if (fetchedForm.data && size(fetchedForm.data) > 0) {
            setFormData(deepmerge(erbausschlagungConfig.initialData, fetchedForm.data));
          } else {
            setFormData(erbausschlagungConfig.initialData);
          }
        }

        setIsLoading(false);
      }
    };

    void getData();

    return () => {
      resetStore();
      // Anything in here is fired on component unmount. das
    };
  }, [ resetStore, setFormConfig, setFormData, setFormType, setNonce, uuid ]);

  if (!uuid) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  if (!isLoading && !form) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  return (
    <>
      <div className="dashboard-navi">
        <Link to={APP_ROUTES.HOME} className="inline-flex items-center gap-2">
          <Icon
            size={ICON_SIZE.S}
            iconName="ArrowLeftIcon"
          />
          Zurück
        </Link>
      </div>
      <div className="flex xs-max:flex-col gap-4 w-full">
        <div className="categorization-detail categorization-detail-new content w-full">
          {isLoading && (<div>Loading</div>)}
          {formSendSuccess && (
            <div>
              <Icon iconName="CheckIcon" size={ICON_SIZE.L} />
              <h1>Vielen Dank für Ihre Angaben</h1>
              <p>Wir haben Ihre Angaben erhalten und werden uns in Kürze bei Ihnen melden.</p>
            </div>
          )}
          {(!formSendSuccess && !isLoading && form) && (
            <FormWizard />
          )}
          <DebugContainer />
        </div>
      </div>
    </>
  )
  ;
};

export default FormUserPage;
