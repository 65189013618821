import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// import { getToken, isCustomer, removeUserSession } from '../lib/auth';
import { APP_ROUTES } from '../constants';
import { getToken, removeUserSession } from '../lib/auth';

const CustomerRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = queryParams.get('redirectTo');

  // if (!getToken() || !isCustomer()) {
  if (!getToken()) {
    removeUserSession();

    // eslint-disable-next-line no-console
    console.log({ queryParams });

    const urlToRedirect: string[] = [ APP_ROUTES.LOGIN ];

    if (redirectTo) {
      urlToRedirect.push(`?redirectTo=${redirectTo}`);
    }

    return <Navigate to={urlToRedirect.join('')} replace />;
  }

  return <>{children}</>;
};

export default CustomerRoute;
