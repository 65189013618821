import { getAddressUi } from '../base/address-ui';

import { newClientSchema } from './schema';

export const newClientConfig: {
  title: string;
  initialData: any;
  schema: any;
  uischema: any;
} = {
  title: 'Mandant anlegen',
  schema: newClientSchema,
  uischema: {
    type: 'Group',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/client/properties/firstname'
          },
          {
            type: 'Control',
            scope: '#/properties/client/properties/lastname'
          }
        ]
      },
      ...getAddressUi('client', ''),
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/client/properties/phone'
          },
          {
            type: 'Control',
            scope: '#/properties/client/properties/email'
          }
        ]
      }
    ]
  },
  initialData: {
    client: {}
  }
};
