import type { ControlProps } from '@jsonforms/core';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import React from 'react';

const CustomCheckboxRenderer = ({
  data,
  path,
  handleChange,
  id,
  schema,
  errors
}: PropsWithChildren<ControlProps>): React.ReactElement => {
  const handleCheckboxChange = (value: any): void => {
    const updatedData = data ? [ ...data ] : [];
    const index = updatedData.indexOf(value);

    if (index === -1) {
      updatedData.push(value);
    } else {
      updatedData.splice(index, 1);
    }

    handleChange(path, updatedData);
  };

  const { items } = schema || {};
  const enumValues = items && 'enum' in items ? items.enum : [];
  const hasError = !!errors;

  if (!enumValues || enumValues.length === 0) {
    return (
      <div className="control bg-error text-white">
        <pre>
          {JSON.stringify(items, null, 2)}
        </pre>
        Multi Checkbox Render issue
      </div>
    );
  }

  return (
    <div className={classNames('control', {
      error: hasError
    })}>
      <div className="checkbox-wrap">
        {enumValues.map((value: any) => (
          <div className="checkbox-option" key={value}>
            <input
              type="checkbox"
              id={`${id}-${value}`}
              checked={data && data.includes(value)}
              onChange={() => handleCheckboxChange(value)}
            />
            <label htmlFor={`${id}-${value}`}>{value}</label>
          </div>
        ))}
      </div>
      {hasError && (
        <div className="validation validation_error">
          {errors}
        </div>
      )}
    </div>
  );
};

export default CustomCheckboxRenderer;
