import { addressProps } from './address';

export const formEndProps = {
  other_notes: {
    type: 'string',
    title: 'Sonstige Anmerkungen'
  },
  form_person: {
    type: 'string',
    title: 'Name des Ausfüllenden',
    description: 'Gimme more'
  },
  contact_via: {
    type: 'array',
    multicheckbox: true,
    items: {
      type: 'string',
      enum: [ 'E-Mail', 'Telefon', 'Post' ]
    },
    minItems: 1
  },
  form_person_email: {
    type: 'string',
    title: 'E-Mail des Ausfüllenden'
  },
  form_person_phone: {
    type: 'string',
    title: 'Telefon des Ausfüllenden'
  },
  form_person_address: {
    type: 'object',
    properties: {
      ...addressProps
    }
  },
  draft_via: {
    type: 'array',
    multicheckbox: true,
    items: {
      type: 'string',
      enum: [ 'E-Mail', 'Post' ]
    },
    minItems: 1
  },
  privacy: {
    type: 'boolean',
    const: true,
    title: 'Ich habe die Datenschutzhinweise gelesen.'
  }
};
