export const getAddressUi = (type = '', addressLabel = 'Adresse'): any => {
  const typeToUse = type ? `${type}/properties/` : '';
  const scope = `#/properties/${typeToUse}`;

  return [
    {
      type: 'Group',
      label: addressLabel,
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: `${scope}street_number`
            }
          ]
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: `${scope}zip`
            },
            {
              type: 'Control',
              scope: `${scope}city`
            }
          ]
        }
      ]
    }
  ];
};
