import type { JsonSchema } from '@jsonforms/core';

import { addressProps } from '../base/address';
import { personBaseWithAddressRequired } from '../base/person';

export const newClientSchema: JsonSchema = {
  type: 'object',
  properties: {
    client: {
      type: 'object',
      properties: {
        firstname: {
          type: 'string',
          title: 'Vorname(n)'
        },
        lastname: {
          type: 'string',
          title: 'Nachname'
        },
        ...addressProps,
        email: {
          type: 'string',
          title: 'E-Mail'
        },
        phone: {
          type: 'string',
          title: 'Telefon'
        }
      },
      required: [
        ...personBaseWithAddressRequired,
        'email'
      ]
    }
  }
};
