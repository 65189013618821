import type { ControlProps } from '@jsonforms/core';
import type { PropsWithChildren } from 'react';
import React from 'react';

const CustomToggleRenderer = ({
  data,
  path,
  handleChange,
  label,
  visible,
  enabled
  // ...props
}: PropsWithChildren<ControlProps>): React.ReactElement | null => {
  if (!visible) {
    return null;
  }

  return (
    <div className="control">
      <label>{label}</label>
      {enabled ? (
        <div className="radio">
          <div
            className="radio-option"
            onClick={() => handleChange(path, true)}
          >
            <input
              type="radio"
              checked={data || false}
              readOnly
            />
            <label>
              Ja
            </label>
          </div>
          <div
            className="radio-option"
            onClick={() => handleChange(path, false)}
          >
            <input
              type="radio"
              checked={!data || false}
              readOnly
            />
            <label>
              Nein
            </label>
          </div>
        </div>
      ) : (
        <strong>
          {data ? 'Ja' : 'Nein'}
        </strong>
      )}
    </div>
  );
};

export default CustomToggleRenderer;
