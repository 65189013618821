import { JsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { vanillaCells, vanillaRenderers } from '@jsonforms/vanilla-renderers';
import type { FC } from 'react';
import React from 'react';
import { useMemo, useState } from 'react';

import { JSON_FORMS_VALIDATION_MODE } from '../constants';
import CustomBooleanRenderer from '../renderer/CustomCheckbox';
import CustomCheckboxRenderer from '../renderer/CustomCheckboxMulti';
import CustomCheckboxMultiTester from '../renderer/CustomCheckboxMultiTester';
import CustomCheckboxTester from '../renderer/CustomCheckboxTester';
import CustomToggleRenderer from '../renderer/CustomToggle';
import CustomToggleTester from '../renderer/CustomToggleTester';
import { translations } from '../translations';

interface IForm {
  schema: any;
  uischema: any;
  showValidation: boolean;
  formData: any;
  setFormData?: (data: any) => void;
  readonly?: boolean;
}

const FormHelper: FC<IForm> = ({
  schema,
  uischema,
  showValidation = false,
  formData,
  setFormData,
  readonly = false
}) => {
  const createTranslator = (locale: any) => (key: any, defaultMessage: any) => {
    // eslint-disable-next-line no-console
    // console.log(`Locale: ${locale}, Key: ${key}, Default Message: ${defaultMessage}`);

    // return locale === 'de' ? defaultMessage : key;

    // @ts-ignore dasidusaid dalsdjka
    const translation = translations[locale][key];

    return translation || defaultMessage;
  };

  const [ locale ] = useState<'de' | 'en'>('de');
  const translation = useMemo(() => createTranslator(locale), [ locale ]);

  return (
    <JsonForms
      i18n={{ locale, translate: translation }}
      config={{
        // restrict: false,
        // trim: false,
        showUnfocusedDescription: true
      }}
      schema={schema}
      uischema={uischema}
      data={formData}
      validationMode={
        showValidation ? JSON_FORMS_VALIDATION_MODE.VALIDATE_AND_SHOW : JSON_FORMS_VALIDATION_MODE.NO_VALIDATION
      }
      cells={vanillaCells}
      renderers={[
        ...vanillaRenderers,
        // { tester: datePickerTester, renderer: withJsonFormsControlProps(DatePickerRenderer) },
        { tester: CustomToggleTester, renderer: withJsonFormsControlProps(CustomToggleRenderer) },
        { tester: CustomCheckboxTester, renderer: withJsonFormsControlProps(CustomBooleanRenderer) },
        { tester: CustomCheckboxMultiTester, renderer: withJsonFormsControlProps(CustomCheckboxRenderer) }
      ]}
      readonly={readonly}
      onChange={setFormData}
    />
  );
};

export default FormHelper;
