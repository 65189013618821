import { and, isBooleanControl, rankWith, schemaMatches } from '@jsonforms/core';
export default rankWith(
  3,
  and(
    isBooleanControl,
    schemaMatches(schema => {
      // @ts-ignore dajksljdksl
      return schema.toggle === true;
    })
  )
);
