import type { FC } from 'react';
import React from 'react';

const SettingsAgencyPage: FC = () => {
  return (
    <>
      <div className="dashboard-head">
        <div>
          <h1>Einstellungen</h1>
        </div>
      </div>
      <p>
        WIP!
      </p>
    </>
  );
};

export default SettingsAgencyPage;
