import AJV from 'ajv';
import addFormats from 'ajv-formats';
import { isArray, map, size } from 'lodash';

const ajv = new AJV({
  allErrors: true,
  verbose: true,
  strict: false
  // ...options,
});

addFormats(ajv);

export const validateForm = (schema: object, data: object): {
  isValid: boolean;
  errors: (string | undefined)[];
} => {
  const validate = ajv.compile(schema);
  const isValid = validate(data);

  return {
    isValid,
    errors: map(validate.errors, 'message')
  };
};

type TSchemaType = 'required' | 'string' | 'number' | 'boolean' | 'date' | 'one of string';

export interface ISchema {
  [key: string]: TSchemaType | ISchema | ISchema[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateData = (schema: any, data: any, parentKey = ''): {
  isValid: boolean;
  failedProps: string[];
} => {
  let isValid = true;
  const failedProps: string[] = [];

  if (parentKey === '' && size(data) === 0) {
    return { isValid: false, failedProps: [] };
  }

  for (const key in schema) {
    const schemaType = schema[key];
    const dataType = typeof data[key];

    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    // Check if the property exists in the data
    if (!(key in data)) {
      isValid = false;
      failedProps.push(fullKey);
      continue; // Skip further validation for this key
    }

    if (isArray(schemaType)) {
      // Validate array of objects
      if (
        !isArray(data[key])
        || !data[key].every((item: any) => validateData(schemaType[0], item, fullKey).isValid)
      ) {
        isValid = false;
        failedProps.push(fullKey);
      }
    } else if (typeof schemaType === 'object') {
      // Validate nested object
      const nestedValidation = validateData(schemaType, data[key], fullKey);

      if (!nestedValidation.isValid) {
        isValid = false;
        failedProps.push(...nestedValidation.failedProps);
      }
    } else {
      let isFieldValid = true;

      switch (schemaType) {
        case 'required':
        case 'string':
        case 'number':
        case 'boolean':
          if (dataType !== schemaType) {
            isFieldValid = false;
          }
          break;
        case 'date':
          if (isNaN(Date.parse(data[key]))) {
            isFieldValid = false;
          }
          break;
        case 'one of string':
          if (
            !isArray(data[key])
            || data[key].length === 0
            || !data[key].every((item: any) => typeof item === 'string')
          ) {
            isFieldValid = false;
          }
          break;
        default:
          isFieldValid = false;
      }

      if (!isFieldValid) {
        isValid = false;
        failedProps.push(fullKey);
      }
    }
  }

  return { isValid, failedProps };
};
