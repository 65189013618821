import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isEmail } from '../lib/email';
import { isValidRedirect } from '../lib/url';
import { getNonce, sendAuth } from '../services/api/app';

import Alert from './Alert';

interface ILoginForm {
  message?: string;
}

const LoginForm: FC<ILoginForm> = ({
  message: messageFromProps = ''
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = queryParams.get('redirectTo') || undefined;
  const [ success, setSuccess ] = useState<boolean>(false);
  const [ message, setMessage ] = useState<string>(messageFromProps);
  const [ email, setEmail ] = useState<string>('');
  const [ nonce, setNonce ] = useState<string>('');
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const { nonce: nonceToSet } = await getNonce();

      if (nonceToSet) {
        setNonce(nonceToSet);
      }
    };

    void getData();

    return () => {
      // @TODO: cancel token
    };
  }, []);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!isLoading && isEmail(email)) {
      setIsLoading(true);

      const data = await sendAuth({
        email,
        nonce,
        redirectTo: isValidRedirect(redirectTo) ? redirectTo : undefined
      });

      setSuccess(data.ok);
      setMessage(data.msg || '');
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {message && (
        <Alert message={message} success={success}></Alert>
      )}
      {!success && (
        <form onSubmit={e => void handleLogin(e)}>
          <div className="form-wrapper flex-col gap-4">
            <p>
              Bitte geben Sie Ihre E-Mail Adresse ein, wir schicken Ihnen einen Link zum Login.
            </p>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)} placeholder="E-Mail"
            />
            <button
              type="submit"
              className="btn btn-brand"
              disabled={!isLoading && !!nonce && !isEmail(email)}
            >
              {isLoading ? 'Loading' : 'Login Link anfordern'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default LoginForm;
