import { size, times } from 'lodash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

import CardSkeleton from '../component/CardSkeleton';
import CardSubmission from '../component/CardSubmission';
import SkeletonText from '../component/SkeletonText';
import { SKELETON_HEIGHT, SKELETON_WIDTH } from '../constants';
import { getMe } from '../services/api/app';
import type { TSubmission, TUser } from '../types/user';

const FormsUserPage: FC = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ user, setUser ] = useState<TUser>();

  useEffect(() => {
    const getUserData = async (): Promise<void> => {
      const { user: fetechedUser } = await getMe();

      setIsLoading(false);

      if (fetechedUser) {
        setUser(fetechedUser);
      }
    };

    void getUserData();

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <>
      <div className="dashboard-head">
        {isLoading ? (
          <div>
            <h1><SkeletonText width={SKELETON_WIDTH.L} height={SKELETON_HEIGHT.L} /></h1>
            <div className="py-4 flex gap-6 text-xs text-base-80">
              {times(3, index => (
                <div key={index}><SkeletonText /></div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h1>Willkommen {user?.fullname}</h1>
          </div>
        )}
      </div>
      <div className="flex gap-4">
        <div className="w-full">
          <div className="flex-table submission-table">
            <div className="thead">
              <div className="tr">
                <div>Name</div>
                <div>Status</div>
                <div>Deadline</div>
              </div>
            </div>
            <div className="tbody">
              {isLoading && (<>
                {times(5, index => (
                  <CardSkeleton key={index} />
                ))}
              </>)}
              {!isLoading && (
                <>
                  {size(user?.submissions) > 0 ? (
                    <>
                      {user?.submissions?.map((submission: TSubmission) => {
                        const {
                          uuid: submissionUuid,
                          title,
                          form_type_name,
                          status_name,
                          status,
                          due_at
                        } = submission;

                        return (
                          <CardSubmission
                            key={submissionUuid}
                            uuid={submissionUuid}
                            title={title}
                            name={form_type_name}
                            statusName={status_name}
                            dueAt={due_at}
                            status={status}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div className="no-result">Keine Formulare</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormsUserPage;
