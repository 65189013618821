import { size } from 'lodash';
import create from 'zustand';

import { validateForm } from '../../lib/validation';
import type { IFormConfig, TFormSteps } from '../../types/form';

interface IFormState {
  nonce?: string;
  formData?: any;
  formType?: string;
  steps?: any;
  percentage?: number;
  currentStep: number;
  config?: any;
  formConfig?: IFormConfig;
  formSendSuccess?: boolean;
  setFormSendSuccess: (success: boolean) => void;
  showValidation: boolean;
  resetOrderState?: () => void;
  setNonce: (nonce: string | undefined) => void;
  setFormConfig: (formConfig: IFormConfig | undefined) => void;
  setFormType: (formType: string | undefined) => void;
  setSteps: (steps: TFormSteps) => void;
  setCurrentStep: (step: number) => void;
  setShowValidation: (showValidation: boolean) => void;
  setNextStep: () => void;
  setPrevStep: () => void;
  resetStore: () => void;
  setFormData: (data: IFormState['formData']) => void;
  setConfig: (config: any) => void;
  validateForm: () => { isValid: boolean; errors: (string | undefined)[] };
}

const useFormStore = create<IFormState>(set => ({
  formData: {},
  steps: {},
  percentage: 0,
  currentStep: 0,
  showValidation: false,
  formSendSuccess: false,
  setFormSendSuccess: (success: boolean) => set({ formSendSuccess: success }),
  formType: '',
  setSteps: (steps: TFormSteps) => set({ steps }),
  setFormConfig: (formConfig?: IFormConfig) => set({ formConfig }),
  setNonce: (nonce?: string) => set({ nonce }),
  setFormType: (formType?: string) => set({ formType }),
  setShowValidation: (showValidation: boolean) => set({ showValidation }),
  setCurrentStep: (step: number) => set({ currentStep: step }),
  setNextStep: () => set(state => ({ currentStep: state.currentStep + 1 })),
  setPrevStep: () => set(state => ({ currentStep: state.currentStep - 1 })),
  setFormData: data => {
    // eslint-disable-next-line no-console
    console.log(data);

    set({ formData: data });
  },
  resetStore: () => {
    set({
      formData: {},
      formConfig: undefined,
      steps: undefined,
      percentage: 0,
      currentStep: 0,
      showValidation: false,
      formSendSuccess: false,
      formType: ''
    });
  },
  setConfig: config => set({ config }),
  validateForm: (): { isValid: boolean; errors: (string | undefined)[] } => {
    const { formConfig, formData } = useFormStore.getState();

    if (
      !formConfig
      || size(formConfig) === 0
      || !formData
      || size(formData) === 0
    ) {
      return { isValid: false, errors: [] };
    }

    return validateForm(formConfig.schema, formData);
  }
}));

export default useFormStore;
