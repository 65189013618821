import type { FC } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { APP_ROUTES } from '../constants';
import { getToken, isAdmin, isCustomer } from '../lib/auth';

import FormsUserPage from './FormsUserPage';
import UsersAgencyPage from './UsersAgencyPage';

const DashboardPage: FC = () => {
  if (!getToken()) {
    return <Navigate to={APP_ROUTES.LOGIN} replace />;
  }

  return (
    <>
      {isAdmin() && (
        <UsersAgencyPage />
      )}
      {isCustomer() && (
        <FormsUserPage />
      )}
    </>
  );
};

export default DashboardPage;
