import { USER_TYPE } from '../constants';
import type { TUser } from '../types/user';

export const getUser = (): TUser | null => {
  const userStr = sessionStorage.getItem('user');

  if (userStr) {
    return JSON.parse(userStr);
  }

  return null;
};

export const isAdmin = (): boolean => {
  const user = getUser();

  return !!user && parseInt(user.type, 10) === USER_TYPE.ADMIN;
};

export const isCustomer = (): boolean => {
  const user = getUser();

  return !!user && parseInt(user.type, 10) === USER_TYPE.CUSTOMER;
};

export const getToken = (): string | null => {
  return sessionStorage.getItem('token') || null;
};

export const removeUserSession = (): void => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

export const setUserSession = ( token: string, user?: TUser ): void => {
  sessionStorage.setItem('token', token);

  if (user) {
    sessionStorage.setItem('user', JSON.stringify(user, null, 2));
  }
};
