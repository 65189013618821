import { newSubmissionSchema } from './schema';

export const newSubmissionConfig: {
  title: string;
  initialData: any;
  schema: any;
  uischema: any;
} = {
  title: 'Erbausschlagung',
  schema: newSubmissionSchema,
  uischema: {
    type: 'Group',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/title'
      },
      {
        type: 'Control',
        scope: '#/properties/due_at'
      }
    ]
  },
  initialData: {
    title: '',
    due_at: new Date()
  }
};
