import type { JsonSchema } from '@jsonforms/core';

import { addressRequired } from '../base/address';
import { formEndProps } from '../base/form-end';
import { personProps, personRequired } from '../base/person';

export const erbausschlagungSchema: JsonSchema = {
  type: 'object',
  properties: {
    deceasedPerson: {
      type: 'object',
      properties: {
        ...personProps,
        city_death: {
          type: 'string',
          title: 'Sterbeort',
          description: 'Beschreibung Sterbeort'
        },
        date_of_death: {
          type: 'string',
          format: 'date',
          title: 'Sterbedatum'
        },
        relation_to_death_person: {
          type: 'string',
          title: 'Die verstorbene Person war Ihr/e',
          enum: [
            'Ehemann',
            'Ehefrau',
            'Vater',
            'Mutter',
            'Großvater',
            'Großmutter',
            'Urgroßvater',
            'Urgroßmutter',
            'Bruder',
            'Schwester',
            'Onkel',
            'Tante',
            'Großonkel',
            'Großtante',
            'Cousin',
            'Cousine',
            'Sohn',
            'Tochter',
            'Enkel',
            'Enkelin',
            'Urenkel',
            'Urenkelin',
            'Unbekannt',
            'Sonstige'
          ]
        }
      },
      required: [
        ...personRequired,
        'city_death',
        'date_of_death',
        'relation_to_death_person'
      ]
    },
    personalData: {
      type: 'object',
      properties: {
        ...personProps,
        children_available: {
          type: 'boolean',
          // @ts-ignore das da asd ad
          toggle: true,
          title: 'Haben Sie Kinder?'
        },
        children: {
          type: 'array',
          title: 'Kinder',
          items: {
            type: 'object',
            properties: {
              ...personProps
            },
            required: [
              ...personRequired
            ]
          }
        },
        planned_children: {
          type: 'boolean',
          // @ts-ignore das da asd ad
          toggle: true,
          title: 'Erwarten Sie ein (weiteres) Kind?'
        },
        planned_children_data: {
          type: 'object',
          title: 'Angaben zum erwarteten Kind bzw. Kinder',
          properties: {
            relation: {
              type: 'string',
              enum: [
                'Mutter',
                'Vater'
              ]
            },
            estimated_day_of_birth: {
              type: 'string',
              format: 'date',
              title: 'Voraussichtlicher Geburtstermin'
            },
            married: {
              type: 'boolean',
              // @ts-ignore das da asd ad
              toggle: true,
              title: 'Sind sie verheiratet?'
            },
            father_relation: {
              type: 'boolean',
              // @ts-ignore das da asd ad
              toggle: true,
              title: 'Hat der Vater des erwarteten Kindes bereits eine Sorgeerklärung abgegeben?'
            }
          }
        }
      },
      required: [
        ...personRequired
      ]
    },
    more_data: {
      type: 'object',
      properties: {
        notice_death: {
          type: 'string',
          format: 'date',
          title: 'Seit wann wissen Sie von dem Tod?'
        },
        notice_inheritance: {
          type: 'string',
          format: 'date',
          title: 'Seit wann wissen Sie, dass Sie als Erbe in Betracht kommen?'
        },
        reason_option: {
          title: 'Was ist der Grund der Ausschlagung?',
          type: 'string',
          enum: [
            'Der Nachlass ist vermutlich überschuldet',
            'Der Nachlass ist sicher überschuldet',
            'Ich möchte vom Erblasser nicht erben',
            'Die Nachlassabwicklung ist mir zu aufwändig',
            'Ich möchte die Erbschaft einer anderen Person zufallen lassen',
            'Sonstige Gründe'
          ]
        },
        value: {
          type: 'string',
          title: 'Geschätzter Nachlasswert (EUR)'
        },
        reason: {
          type: 'string',
          title: 'Grund'
        },
        waive_inheritance_children: {
          type: 'boolean',
          // @ts-ignore das da asd ad
          toggle: true,
          // eslint-disable-next-line max-len
          title: 'Ich/wir schlagen auch für das noch zu gebärende Kind/Kinder die Erbschaft aus allen Berufungsgründen aus'
        },
        report_of_probate_court: {
          type: 'boolean',
          // @ts-ignore das da asd ad
          toggle: true,
          title: 'Liegt Ihnen ein Schreiben des Nachlassgerichts vor?'
        },
        report_of_probate_court_date_of_report: {
          type: 'string',
          format: 'date',
          title: 'Datum des Schreibens'
        },
        report_of_probate_court_file_number: {
          type: 'string',
          title: 'Akktenzeichen'
        }
      },
      required: [
        'reason_option',
        'notice_death',
        'notice_inheritance',
        'waive_inheritance_children',
        'report_of_probate_court'
      ],
      allOf: [
        {
          if: {
            properties: {
              report_of_probate_court: {
                const: true
              }
            }
          },
          then: {
            properties: {
              report_of_probate_court_date_of_report: {
                minLength: 1
              },
              report_of_probate_court_file_number: {
                minLength: 1
              }
            },
            required: [ 'report_of_probate_court_date_of_report', 'report_of_probate_court_file_number' ]
          }
        },
        {
          if: {
            properties: {
              reason_option: {
                anyOf: [
                  { const: 'Ich möchte vom Erblasser nicht erben' },
                  { const: 'Ich möchte die Erbschaft einer anderen Person zufallen lassen' },
                  { const: 'Die Nachlassabwicklung ist mir zu aufwändig' },
                  { const: 'Sonstige Gründe' }
                ]
              }
            }
          },
          then: {
            properties: {
              value: {
                minLength: 1
              }
            },
            required: [ 'value' ]
          }
        },
        {
          if: {
            properties: {
              reason_option: {
                const: 'Sonstige Gründe'
              }
            }
          },
          then: {
            properties: {
              reason: {
                minLength: 1
              }
            },
            required: [ 'reason' ]
          }
        }
      ]
    },
    form_end: {
      type: 'object',
      properties: {
        ...formEndProps
      },
      required: [
        'contact_via',
        'form_person',
        'privacy'
      ],
      allOf: [
        {
          if: {
            properties: {
              contact_via: {
                contains: {
                  const: 'Post'
                }
              }
            }
          },
          then: {
            properties: {
              form_person_address: {
                required: [ ...addressRequired ]
              }
            }
          }
        },
        {
          if: {
            properties: {
              contact_via: {
                contains: {
                  const: 'Telefon'
                }
              }
            }
          },
          then: {
            properties: {
              form_person_phone: {
                minLength: 1
              }
            },
            required: [ 'form_person_phone' ]
          }
        },
        {
          if: {
            properties: {
              contact_via: {
                contains: {
                  const: 'E-Mail'
                }
              }
            }
          },
          then: {
            properties: {
              form_person_email: {
                type: 'string',
                format: 'email'
              }
            },
            required: [ 'form_person_email' ]
          }
        }
      ]
    }
  }
};
