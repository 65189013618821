import type { APP_ROUTES } from '../constants';
import type { TStringWithLeadingSlash, TStringWithTrailingSlash } from '../types/url';

export function ensureTrailingSlash(url: string): TStringWithTrailingSlash {
  return url.endsWith('/') ? (url as TStringWithTrailingSlash) : `${url}/`;
}

export function ensureLeadingSlash(url: string): TStringWithLeadingSlash {
  return url.startsWith('/') ? (url as TStringWithLeadingSlash) : `/${url}`;
}

export function removeTrailingSlash(url: string): string {
  return url.replace(/\/$/, '');
}

export function removeLeadingSlash(url: string): string {
  return url.replace(/^\//, '');
}

export function removeQueryParams(url: string): string {
  return url.split('?')[0];
}

export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }

  return true;
}

export function replaceRouteParam(route: APP_ROUTES, param?: string | number): string {
  if (!param) {
    return route;
  }

  return removeTrailingSlash(route)
    .split('/')
    .slice(0, -1)
    .join('/') + '/' + param;
}

export function isValidRedirect(route?: string): boolean {
  if (!route) {
    return false;
  }

  return true;
}
