import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { erbausschlagungConfig } from '../../forms/erbausschlagung/config';
import { submitSubmission } from '../../services/api/app';
import useFormStore from '../../services/store/form';
import Alert from '../Alert';
import LoadingSpinner from '../LoadingSpinner';

const UserFormSidebar: FC = () => {
  const { uuid } = useParams();
  const [ isSaved, setIsSaved ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const {
    setCurrentStep,
    currentStep,
    setShowValidation,
    showValidation,
    formData,
    setFormSendSuccess,
    validateForm
  } = useFormStore(state => state);

  const config = erbausschlagungConfig;
  const steps = config.steps;

  const {
    isValid
  } = validateForm();

  const handleSend = async (): Promise<void> => {
    if (uuid) {
      if (isValid) {
        if (!isLoading) {
          setIsLoading(true);

          const { success } = await submitSubmission(uuid, {
            action: 'submit',
            form: formData
          });

          setIsLoading(false);
          setFormSendSuccess(!!success);
        }
      } else {
        setShowValidation(true);
      }
    }
  };

  const handelSubmit = async (): Promise<void> => {
    if (!isLoading && uuid) {
      setIsLoading(true);
      setIsSaved(false);
      setShowValidation(false);

      const { success } = await submitSubmission(uuid, {
        action: 'save',
        form: formData
      });

      setIsSaved(!!success);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="sidebar-title mt-8">
        {config.title}
      </div>
      <div className="sidebar-navi">
        {steps && steps.map((step, index) => (
          <div
            key={step.title}
            onClick={() => setCurrentStep(index)}
            className={classNames({
              active: currentStep === index
            })}
          >
            {index + 1}. {step.title}
          </div>
        ))}
      </div>
      <div className="sidebar-cta">
        <button
          className="btn"
          onClick={() => void handelSubmit()}
          disabled={isLoading}
        >
          {isLoading && (
            <LoadingSpinner />
          )}
          Formular speichern
        </button>
        <button
          className={classNames('btn btn-brand', {
            disabled: !isValid
          })}
          // disabled={!isValid}
          disabled={isLoading}
          onClick={() => void handleSend()}
        >
          {isLoading && (
            <LoadingSpinner />
          )}
          Formular an das Notariat senden
        </button>
        {isSaved && (
          <Alert success>
            Das Formular wurde erfolgreich gespeichert.
          </Alert>
        )}
        {!isValid && showValidation && (
          <Alert>
            Bitte überprüfen Sie Ihre Eingaben.
            Alle Pflichtfelder müssen ausgefüllt sein bevor Sie das Formular absenden können.
          </Alert>
        )}
      </div>
    </>
  );
};

export default UserFormSidebar;
