import type { FC } from 'react';

import { SKELETON_WIDTH } from '../constants';

import SkeletonText from './SkeletonText';

const CardSkeleton: FC = () => {
  return (
    <div className="tr status open animate-pulse">
      <div>
        <SkeletonText width={SKELETON_WIDTH.L} />
      </div>
      <div>
        <SkeletonText width={SKELETON_WIDTH.XS} />
      </div>
      <div>
        <SkeletonText />
      </div>
    </div>
  );
};

export default CardSkeleton;
