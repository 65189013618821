import { newCommentSchema } from './schema';

export const newCommentConfig: {
  title: string;
  initialData: any;
  schema: any;
  uischema: any;
} = {
  title: 'Erbausschlagung',
  schema: newCommentSchema,
  uischema: {
    type: 'Group',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/text',
        options: {
          multi: true
        }
      }
    ]
  },
  initialData: {
    comment: ''
  }
};
