import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { APP_ROUTES, FORM_STATUS_ID, ICON_SIZE } from '../constants';
import { getStatusClass } from '../lib/status';
import { replaceRouteParam } from '../lib/url';
import { deleteSubmission } from '../services/api/app';

import Icon from './Icon';
import LoadingSpinner from './LoadingSpinner';
import Modal from './Modal';

interface ICardSubmission {
  title: string;
  name: string;
  uuid: string;
  statusName: string;
  dueAt: string;
  status: string | number;
  reload?: (setter: boolean) => void;
  isAdmin?: boolean;
}

const CardSubmission: FC<ICardSubmission> = ({
  title = '',
  name = '',
  statusName = '',
  dueAt = '',
  uuid = '',
  status = FORM_STATUS_ID.OPEN,
  isAdmin = false,
  reload
}) => {
  const titleToUse = title || name;
  const isLinked
    = isAdmin
    || [
      FORM_STATUS_ID.OPEN,
      FORM_STATUS_ID.PROGRESS,
      FORM_STATUS_ID.FEEDBACK
    ].includes(Number(status));
  const urlToUse = isAdmin ? APP_ROUTES.FORM : APP_ROUTES.FORM_USER;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showCloseModal, setShowCloseModal ] = useState(false);
  const openDeleteConfirmation = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (isAdmin) {
      e.stopPropagation();
      e.preventDefault();
      setShowCloseModal(true);
    }
  };
  const closeDeleteConfirmation = (): void => {
    if (!isLoading) {
      setShowCloseModal(false);
    }
  };
  const handleDelete = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);

      const data = await deleteSubmission({
        uuid
      });

      // setSuccess(data.ok);
      // setMessage(data.msg || '');

      setIsLoading(false);

      if (data.ok) {
        setShowCloseModal(false);

        if (reload) {
          reload(true);
        }
      }
    }
  };

  const innerHtml = (
    <div className={classNames('tr status', getStatusClass(status))}>
      <div>{titleToUse}</div>
      <div className="whitespace-nowrap">{statusName}</div>
      <div>{dueAt}</div>
      {isAdmin && (
        <div
          className="icon-warp"
          onClick={openDeleteConfirmation}
        >
          <Icon
            size={ICON_SIZE.S}
            iconName="TrashIcon"
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      {isLinked ? (
        <Link
          to={replaceRouteParam(urlToUse, uuid)}
        >
          {innerHtml}
        </Link>
      ) : (
        <div>
          {innerHtml}
        </div>
      )}
      {isAdmin && showCloseModal && (
        <Modal
          title="Formular Löschen"
          close={closeDeleteConfirmation}
        >
          Wollen Sie wirklich das Formular <strong>&quot;{title}&quot;</strong> unwiderruflich löschen?
          <div className="btn-group">
            <button
              className="btn"
              onClick={closeDeleteConfirmation}
            >
              Abbrechen
            </button>
            <button
              className="btn btn-error"
              onClick={() => void handleDelete()}
              disabled={isLoading}
            >
              {isLoading && (
                <LoadingSpinner />
              )}
              Löschen
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CardSubmission;
