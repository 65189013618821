import { getAddressUi } from './address-ui';

export const getFormEndUi = (type = 'form_end'): any => {
  const typeToUse = type ? `${type}/properties/` : '';
  const scope = `#/properties/${typeToUse}`;

  return [
    {
      type: 'Group',
      elements: [
        {
          type: 'Control',
          scope: `${scope}other_notes`,
          options: {
            multi: true
          }
        },
        {
          type: 'Control',
          scope: `${scope}form_person`
        }
      ]
    },
    {
      type: 'Group',
      label: 'Kontakt',
      elements: [
        {
          type: 'Control',
          scope: `${scope}contact_via`
        },
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: `${scope}form_person_email`
            }
          ],
          rule: {
            effect: 'SHOW',
            condition: {
              scope: `${scope}contact_via`,
              schema: { contains: { const: 'E-Mail' }}
            }
          }
        },
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: `${scope}form_person_phone`
            }
          ],
          rule: {
            effect: 'SHOW',
            condition: {
              scope: `${scope}contact_via`,
              schema: { contains: { const: 'Telefon' }}
            }
          }
        },
        {
          type: 'Group',
          elements: [
            ...getAddressUi('form_end/properties/form_person_address')
          ],
          rule: {
            effect: 'SHOW',
            condition: {
              scope: `${scope}contact_via`,
              schema: { contains: { const: 'Post' }}
            }
          }
        }
      ]
    },
    {
      type: 'Group',
      label: 'Entwurf erhalten via',
      elements: [
        {
          type: 'Control',
          scope: `${scope}draft_via`
        }
      ]
    },
    {
      type: 'Group',
      label: 'Datenschutz',
      elements: [
        {
          type: 'Control',
          scope: `${scope}privacy`
        }
      ]
    }
  ];
};

export const getFormEndUiFull = (): any => {
  return {
    type: 'Group',
    elements: [
      ...getFormEndUi()
    ]
  };
};
