import { and, isBooleanControl, rankWith, schemaMatches } from '@jsonforms/core';
export default rankWith(
  3,
  and(
    isBooleanControl,
    // @ts-ignore dajksljdksl
    // schemaMatches(schema => schema.custom === true)
    schemaMatches(schema => {
      // eslint-disable-next-line no-console
      // console.log('fiooo', schema);
      // @ts-ignore dajksljdksl
      // eslint-disable-next-line no-console
      // console.log('fiooo', schema.custom);

      // @ts-ignore dajksljdksl
      return schema.custom === true;
    })
  )
);
