import type { ControlProps } from '@jsonforms/core';
import type { PropsWithChildren } from 'react';
import React from 'react';

const CustomBooleanRenderer = ({
  data,
  path,
  handleChange,
  label
}: PropsWithChildren<ControlProps>): React.ReactElement => (
  <div className="checkbox-option">
    <label>
      <input
        type="checkbox"
        checked={data || false}
        onChange={event => handleChange(path, event.target.checked)}
      />
      {label}
    </label>
  </div>
);

export default CustomBooleanRenderer;
