import type { FC } from 'react';
import { useEffect } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { APP_ROUTES } from './constants';
import './css/app.css';
import { erbausschlagungConfig } from './forms/erbausschlagung/config';
import Layout from './layout/Layout';
import LayoutSidebar from './layout/LayoutSidebar';
import DashboardPage from './pages/Dashboard';
import FormAgencyPage from './pages/FormAgencyPage';
import FormUserPage from './pages/FormUserPage';
import LoginPage from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import SettingsAgencyPage from './pages/SettingsAgencyPage';
import UserAgencyPage from './pages/UserAgencyPage';
import CustomerRoute from './routes/CustomerRoute';
import PrivateRoute from './routes/PrivateRoute';
import useFormStore from './services/store/form';

const App: FC = () => {
  const {
    // nonce,
    setFormData
  } = useFormStore(state => state);

  const { initialData } = erbausschlagungConfig;

  useEffect(() => {
    setFormData(initialData);
  }, [ initialData, setFormData ]);

  return (
    <Router>
      <div className="app-wrapper">
        <Routes>
          <Route element={<Layout />}>
            <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route element={<LayoutSidebar />}>
            {/* AGENCY PAGES */}
            <Route
              path={APP_ROUTES.HOME}
              element={<PrivateRoute><DashboardPage /></PrivateRoute>}
            />
            <Route
              path={APP_ROUTES.USER}
              element={<PrivateRoute><UserAgencyPage /></PrivateRoute>}
            />
            <Route
              path={APP_ROUTES.SETTINGS}
              element={<PrivateRoute><SettingsAgencyPage /></PrivateRoute>}
            />
            <Route
              path={APP_ROUTES.FORM}
              element={<PrivateRoute><FormAgencyPage /></PrivateRoute>}
            />
            {/* USER PAGES */}
            <Route
              path={APP_ROUTES.FORM_USER}
              element={<CustomerRoute><FormUserPage /></CustomerRoute>}
            />
          </Route>
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={3500}
          theme="colored"
        />
      </div>
    </Router>
  );
};

export default App;
