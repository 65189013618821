import * as HeroIconsOutline from '@heroicons/react/24/outline';
import * as HeroIconsSolid from '@heroicons/react/24/solid';
import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { ICON_SIZE, ICON_TYPE } from '../constants';

interface IIcon {
  iconName: string;
  className?: string;
  size?: ICON_SIZE;
  iconStyle?: ICON_TYPE;
}

const Icon: FC<IIcon> = ({
  iconName,
  className,
  iconStyle = ICON_TYPE,
  size = ICON_SIZE.M,
  ...props
}) => {
  const HeroIcons = iconStyle === ICON_TYPE.SOLID ? HeroIconsSolid : HeroIconsOutline;

  // @ts-ignore adsad ad sad13213
  const IconComponent = HeroIcons[iconName];
  const cssClasses = classNames({
    'w-4 h-4': size === ICON_SIZE.S,
    'w-6 h-6': size === ICON_SIZE.M,
    'w-8 h-8': size === ICON_SIZE.L,
    'w-10 h-10': size === ICON_SIZE.XL
  }, className);

  if (!IconComponent) {
    return <HeroIcons.QuestionMarkCircleIcon
      className={cssClasses}
      {...props}
    />;
  }

  return <IconComponent
    className={cssClasses}
    {...props}
  />;
};

export default Icon;
