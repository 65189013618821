import type { FC } from 'react';
import React from 'react';

const LoadingSpinner: FC = () => {
  return (
    <div
      className="
        inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent
        align-[-0.125em] -ml-1 mr-1 motion-reduce:animate-[spin_1.5s_linear_infinite]
      "
      role="status"
    >
      <span
        className="
          !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]
        "
      >
        Lade...
      </span>
    </div>
  );
};

export default LoadingSpinner;
