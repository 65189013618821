import { toast } from 'react-toastify';

import type { TApiResponse } from '../services/api/app';

export function notify(data: TApiResponse): void {
  if (data.ok) {
    if (data.success) {
      toast.success(data.msg);
    } else {
      toast.info(data.msg);
    }
  } else {
    toast.error(data.msg);
  }
}
