import { addressProps, addressRequired } from './address';

export const personBaseProps = {
  title: {
    type: 'string',
    title: 'Anrede, Titel'
  },
  firstname: {
    type: 'string',
    title: 'Vorname(n)'
  },
  lastname: {
    type: 'string',
    title: 'Nachname'
  }
};

export const personProps = {
  ...personBaseProps,
  birth_lastname: {
    type: 'string',
    title: 'Ggf. Geburtsname'
  },
  date_of_birth: {
    type: 'string',
    format: 'date',
    title: 'Geburtsdatum'
  },
  ...addressProps
};

export const personBaseRequired = [
  'firstname',
  'lastname'
];

export const personRequired = [
  ...addressRequired,
  ...personBaseRequired,
  'date_of_birth'
];

export const personBaseWithAddressProps = {
  ...personBaseProps,
  ...addressProps
};

export const personBaseWithAddressRequired = [
  ...addressRequired,
  ...personBaseRequired
];
