import classNames from 'classnames';
import type { FC } from 'react';

import { SKELETON_HEIGHT, SKELETON_WIDTH } from '../constants';

interface ISkeletonText {
  width?: SKELETON_WIDTH;
  height?: SKELETON_HEIGHT;
}

const SkeletonText: FC<ISkeletonText> = ({
  width = SKELETON_WIDTH.DEFAULT,
  height = SKELETON_HEIGHT.DEFAULT
}) => {
  return (
    <div className={classNames(
      'bg-gray-200 rounded-full dark:bg-gray-700',
      width, height
    )} />
  );
};

export default SkeletonText;
