import type { FC } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import AdminSidebar from '../component/Sidebar/Admin';
import UserSidebar from '../component/Sidebar/User';
import SidebarWithChildren from '../component/SidebarWithChildren';
import { isAdmin, isCustomer } from '../lib/auth';

const LayoutSidebar: FC = () => {
  return (
    <div className="form-wrapper">
      <SidebarWithChildren>
        {isAdmin() && (<AdminSidebar />)}
        {isCustomer() && (<UserSidebar />)}
      </SidebarWithChildren>
      <div className="categorization-detail categorization-detail-new content">
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutSidebar;
