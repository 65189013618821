import type { AxiosError } from 'axios';
import axios from 'axios';

import { APP_API_ROUTES } from '../../constants';
import { getToken } from '../../lib/auth';
import { notify } from '../../lib/notification';
import type { TSubmission, TUser } from '../../types/user';

const authToken = getToken();
const appApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined
});

type TAuthProps = { nonce?: string }
& ({ email: string; redirectTo?: string; token?: never }
| { token: string; email?: never });

export type TApiResponse = { ok: boolean; msg?: string; success?: boolean };
type TAuthResponse = TApiResponse & { token?: string; user?: TUser };
type TNonceResponse = TApiResponse & { nonce?: string };

type TSubmitFormResponse = TApiResponse;
type TConfigResponse = TApiResponse & { nonce?: string };
type TUsersAgencyResponse = TApiResponse & { users?: TUser[] };
type TUserAgencyResponse = TApiResponse & { user?: TUser };
type TSaveUserResponse = TApiResponse & { user_uuid?: 'string' };
type TFormsAgencyResponse = TApiResponse & { formTypes?: any };
type TSubmissionsAgencyResponse = TApiResponse & { forms?: TSubmission[] };
type TSubmissionAgencyResponse = TApiResponse & { submission?: TSubmission };

type TApiError = { ok: false; msg: string };

async function handleApiRequest<T extends TApiResponse>(
  request: Promise<{ data: T }>,
  showNotification = false
): Promise<T | TApiError> {
  try {
    const { data } = await request;

    if (showNotification) {
      notify(data);
    }

    return data;
  } catch (error) {
    const typedError = error as AxiosError<TApiError>;

    return typedError.response?.data || { ok: false, msg: typedError.message };
  }
}

export const submitSubmission = async (uuid: string, data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(appApi.post(`${APP_API_ROUTES.SUBMIT_FORM}/${uuid}`, data));
};

export const getAppConfig = async (): Promise<TConfigResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.CONFIG}/`));
};

export const getAdminRoute = async (): Promise<TConfigResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.ADMIN}/`));
};

export const getUsersAgency = async (): Promise<TUsersAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.USERS_AGENCY}/`));
};

export const getUserAgency = async (uuid: string): Promise<TUserAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.USER_AGENCY}/${uuid}`));
};

export const getFormsAgency = async (): Promise<TFormsAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.FORMS_AGENCY}/`));
};

export const getNonce = async (): Promise<TNonceResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.NONCE}/`));
};

export const sendAuth = async (data: TAuthProps): Promise<TAuthResponse> => {
  return handleApiRequest(appApi.post<TAuthResponse>(`${APP_API_ROUTES.AUTH}/`, data));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveUser = async (data: any): Promise<TSaveUserResponse> => {
  if (data && 'uuid' in data && data.uuid) {
    return handleApiRequest(
      appApi.post(`${APP_API_ROUTES.UPDATE_USER}/`, data),
      true
    );
  }

  return handleApiRequest(
    appApi.post(`${APP_API_ROUTES.SAVE_USER}/`, data),
    true
  );
};

export const deleteUser = async (data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(
    appApi.post(`${APP_API_ROUTES.DELETE_USER}/`, data),
    true
  );
};

export const sendSubmissionReminder = async (data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(
    appApi.post(`${APP_API_ROUTES.REMINDER_SUBMISSION}/`, data),
    true
  );
};

export const addSubmission = async (data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(
    appApi.post(`${APP_API_ROUTES.ADD_SUBMISSION}/`, data),
    true
  );
};

export const deleteSubmission = async (data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(appApi.post(`${APP_API_ROUTES.DELETE_SUBMISSION}/`, data));
};

export const addComment = async (data: unknown): Promise<TSubmitFormResponse> => {
  return handleApiRequest(appApi.post(`${APP_API_ROUTES.ADD_COMMENT}/`, data));
};

export const getSubmissionsAgency = async (): Promise<TSubmissionsAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.SUBMISSIONS_AGENCY}`));
};

export const getSubmissionAgency = async (uuid: string): Promise<TSubmissionAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.SUBMISSION_AGENCY}/${uuid}`));
};

export const getMe = async (): Promise<TUserAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.ME}`));
};

export const getForm = async (uuid: string): Promise<TSubmissionAgencyResponse> => {
  return handleApiRequest(appApi.get(`${APP_API_ROUTES.FORM}/${uuid}`));
};
