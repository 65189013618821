import { toInteger } from 'lodash';

import { FORM_STATUS_CLASS, FORM_STATUS_ID } from '../constants';

export const getStatusClass = (
  status: string | number,
  fallBack?: FORM_STATUS_CLASS
): FORM_STATUS_CLASS => {
  const statusToUse = toInteger(status);

  switch (statusToUse) {
    case FORM_STATUS_ID.CLOSED:
      return FORM_STATUS_CLASS.CLOSED;

    case FORM_STATUS_ID.PROGRESS:
      return FORM_STATUS_CLASS.PROGRESS;

    case FORM_STATUS_ID.FEEDBACK:
      return FORM_STATUS_CLASS.ERROR;

    case FORM_STATUS_ID.READY_FOR_FEEDBACK:
      return FORM_STATUS_CLASS.SUCCESS;

    default:
      return fallBack || FORM_STATUS_CLASS.OPEN;
  }
};
