import type { JsonSchema } from '@jsonforms/core';

export const newSubmissionSchema: JsonSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Titel'
    },
    due_at: {
      type: 'string',
      format: 'date',
      title: 'Deadline'
    }
  },
  required: [
    'title', 'due_at'
  ]
};
