export enum ICON_TYPE {
  SOLID = 'solid',
  OUTLINE = 'outline'
}

export enum ICON_SIZE {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl'
}

export enum SKELETON_HEIGHT {
  S = 'h-2',
  DEFAULT = 'h-5',
  M = 'h-5',
  L = 'h-8'
}

export enum SKELETON_WIDTH {
  XS = 'w-16',
  S = 'w-24',
  DEFAULT = 'w-32',
  M = 'w-32',
  L = 'w-64'
}

export enum USER_TYPE {
  ADMIN = 0,
  CUSTOMER = 1
}

export enum APP_API_ROUTES {
  AUTH = 'auth',
  CONFIG = 'config',
  NONCE = 'nonce',
  SUBMIT_FORM = 'submit-submission',
  ADMIN = 'admin',
  ME = 'me',
  USER_AGENCY = 'user-agency',
  USERS_AGENCY = 'users-agency',
  FORMS_AGENCY = 'forms-agency',
  SUBMISSION_AGENCY = 'submission-agency',
  SUBMISSIONS_AGENCY = 'submissions-agency',
  SAVE_USER = 'save-user',
  UPDATE_USER = 'update-user',
  DELETE_USER = 'delete-user',
  ADD_SUBMISSION = 'add-submission',
  DELETE_SUBMISSION = 'delete-submission',
  REMINDER_SUBMISSION = 'reminder-submission',
  ADD_COMMENT = 'add-comment',
  CUSTOMER = 'customer',
  FORM = 'form'
}

export enum APP_ROUTES {
  LOGIN = '/login',
  ADMIN = '/admin',
  CUSTOMER = '/customer',
  HOME = '/',
  SETTINGS = '/einstellungen',
  FORMS = '/formulare',
  FORM_USER = '/formular/:uuid?',
  FORM = '/f/:uuid?',
  USER = '/u/:uuid?'
}

export enum JSON_FORMS_VALIDATION_MODE {
  NO_VALIDATION = 'NoValidation',
  VALIDATE_AND_SHOW = 'ValidateAndShow'
}

export enum FORM_STATUS_ID {
  OPEN = 10,
  PROGRESS = 20,
  READY_FOR_FEEDBACK = 25,
  FEEDBACK = 30,
  CLOSED = 90,
  UNKNOWN = 999
}

export enum FORM_STATUS {
  OPEN = 'unbearbeitet',
  PROGRESS = 'in Bearbeitung',
  READY_FOR_FEEDBACK = 'bereit zur Prüfung',
  FEEDBACK = 'Änderung erforderlich',
  CLOSED = 'geschlossen',
  UNKNOWN = 'unbekannt'
}

export enum FORM_STATUS_CLASS {
  OPEN = 'open',
  PROGRESS = 'in-progress',
  ERROR = 'error',
  DONE = 'done',
  SUCCESS = 'done',
  CLOSED = 'closed'
}

// eslint-disable-next-line no-control-regex,max-len
export const EMAIL_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const EMAIL_PATTERN_STRING = EMAIL_PATTERN.toString().replace(/(^\/|\/$)/g, '');
