import type { FC } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import useFormStore from '../services/store/form';

import FormHelper from './FormHelper';

const FormWizard: FC = () => {
  const {
    formData,
    currentStep,
    setFormData,
    showValidation,
    formConfig,
    setNextStep: handleNextStep,
    setPrevStep: handlePrevStep
  } = useFormStore(state => state);

  const steps = formConfig?.steps;

  if (!steps) {
    return null;
  }

  const percentage = Math.round(currentStep / (steps.length - 1) * 100);

  return (
    <>
      <div className="categorization-detail-header header">
        <div className="progressbar-wrapper">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              strokeLinecap: 'butt'
            })}
          />
          Online-Formular
        </div>
        <div className="header-actions">
          <h2><span>{currentStep + 1}.</span>{steps[currentStep].title}</h2>
          <div className="header-actions-right">
            <div>
              Schritt {currentStep + 1} von {steps.length}
            </div>
            <div>
              <button
                className="btn"
                onClick={handlePrevStep}
                disabled={currentStep === 0}
              >
                Zurück
              </button>
              <button
                className="btn"
                onClick={handleNextStep}
                disabled={currentStep === steps.length - 1}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormHelper
        schema={steps[currentStep].schema}
        uischema={steps[currentStep].uischema}
        formData={formData}
        showValidation={showValidation}
        setFormData={({ data: dataInput }) => setFormData(dataInput)}
      />
      <div className="header-actions-right">
        <div className="ml-auto">
          <button
            className="btn"
            onClick={handlePrevStep}
            disabled={currentStep === 0}
          >
            Zurück
          </button>
          <button
            className="btn"
            onClick={handleNextStep}
            disabled={currentStep === steps.length - 1}
          >
            Weiter
          </button>
        </div>
      </div>
    </>
  );
};

export default FormWizard;
