import type { JsonSchema } from '@jsonforms/core';

export const newCommentSchema: JsonSchema = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
      title: 'Kommentar'
    }
  },
  required: [
    'text'
  ]
};
