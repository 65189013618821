import type { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import Icon from './Icon';

interface IModal {
  children?: React.ReactNode;
  title?: string;
  close: () => void;
}

const Modal: FC<PropsWithChildren<IModal>> = ({
  children,
  title,
  close
}) => {
  return (
    <>
      {createPortal(
        <div className="modal">
          <div className="modal-inner">
            <div className="modal-close" onClick={close}>
              <Icon
                iconName="XMarkIcon"
              />
            </div>
            {title && <div className="modal-head h3">{title}</div>}
            {children}
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default Modal;
