import type { IFormConfig } from '../../types/form';
import { getAddressUi } from '../base/address-ui';
import { getFormEndUiFull } from '../base/form-end-ui';
import { getPersonUi } from '../base/person-ui';

import { erbausschlagungSchema } from './schema';

export const erbausschlagungConfig: IFormConfig = {
  title: 'Erbausschlagung',
  schema: erbausschlagungSchema,
  initialData: {
    deceasedPerson: {},
    personalData: {
      children_available: false,
      planned_children: false,
      planned_children_data: {
        married: true
      }
    },
    more_data: {
      waive_inheritance_children: false,
      report_of_probate_court: false
    },
    form_end: {
      contact_via: [],
      draft_via: [],
      privacy: false
    }
  },
  steps: [
    {
      title: 'Angaben zur verstorbenen Person',
      schema: erbausschlagungSchema,
      uischema: {
        type: 'Group',
        elements: [
          ...getPersonUi('deceasedPerson'),
          ...getAddressUi('deceasedPerson', 'Letzter gewöhnlicher Aufenthalt'),
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/deceasedPerson/properties/city_death',
                description: 'City of Death description'
              },
              {
                type: 'Control',
                scope: '#/properties/deceasedPerson/properties/date_of_death'
              }
            ]
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/deceasedPerson/properties/relation_to_death_person'
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Angaben zur ausschlagenden Person',
      schema: erbausschlagungSchema,
      uischema: {
        type: 'Group',
        elements: [
          ...getPersonUi('personalData'),
          ...getAddressUi('personalData'),
          {
            type: 'Control',
            scope: '#/properties/personalData/properties/children_available'
          },
          {
            type: 'Group',
            label: 'Kinder',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/personalData/properties/children',
                options: {
                  elementLabelProp: 'firstname',
                  detail: {
                    type: 'VerticalLayout',
                    elements: [
                      ...getPersonUi(),
                      ...getAddressUi()
                    ]
                  }
                }
              }
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/personalData/properties/children_available',
                schema: {
                  const: false
                }
              }
            }
          },
          {
            type: 'Control',
            scope: '#/properties/personalData/properties/planned_children'
          },
          {
            type: 'Group',
            label: 'Angaben zum erwarteten Kind bzw. Kinder',
            elements: [
              {
                type: 'Label',
                text: 'Ungeborene Kinder, welche zum Zeitpunkt des Erbfalls bereits gezeugt waren,'
                  + 'sind erbfähig. Daher brauchen wir von Ihnen ein paar zusätzliche Angaben.'
              },
              {
                type: 'Control',
                scope: '#/properties/personalData/properties/planned_children_data/properties/estimated_day_of_birth'
              },
              {
                type: 'Control',
                scope: '#/properties/personalData/properties/planned_children_data/properties/relation',
                label: 'Sind Sie die Mutter oder der Vater des erwarteten Kindes?',
                options: {
                  format: 'radio'
                }
              },
              {
                type: 'Control',
                scope: '#/properties/personalData/properties/planned_children_data/properties/married',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/personalData/properties/planned_children_data/properties/relation',
                    schema: {
                      const: 'Vater'
                    }
                  }
                }
              },
              {
                type: 'Control',
                scope: '#/properties/personalData/properties/planned_children_data/properties/father_relation',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/personalData/properties/planned_children_data/properties',
                    schema: {
                      properties: {
                        married: { const: false },
                        relation: { const: 'Vater' }
                      }
                    }
                  }
                }
              }
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/personalData/properties/planned_children',
                schema: {
                  const: false
                }
              }
            }
          }
        ]
      }
    },
    {
      title: 'Weitere Angaben',
      schema: erbausschlagungSchema,
      uischema: {
        type: 'Group',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/notice_death'
              },
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/notice_inheritance'
              }
            ]
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/reason_option',
                options: {
                  format: 'radio'
                }
              }
            ]
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/reason',
                options: {
                  multi: true
                },
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/more_data/properties/reason_option',
                    schema: {
                      const: 'Sonstige Gründe'
                    }
                  }
                }
              },
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/value',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/more_data/properties/reason_option',
                    schema: {
                      enum: [
                        'Sonstige Gründe',
                        'Ich möchte vom Erblasser nicht erben',
                        'Die Nachlassabwicklung ist mir zu aufwändig',
                        'Ich möchte die Erbschaft einer anderen Person zufallen lassen'
                      ]
                    }
                  }
                }
              }
            ],
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/more_data/properties/reason_option',
                schema: {
                  enum: [
                    'Sonstige Gründe',
                    'Ich möchte vom Erblasser nicht erben',
                    'Die Nachlassabwicklung ist mir zu aufwändig',
                    'Ich möchte die Erbschaft einer anderen Person zufallen lassen'
                  ]
                }
              }
            }
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/waive_inheritance_children',
                options: {
                  format: 'radio'
                }
              }
            ]
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/more_data/properties/report_of_probate_court',
                options: {
                  format: 'radio'
                }
              },
              {
                type: 'Group',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/more_data/properties/report_of_probate_court_date_of_report'
                  },
                  {
                    type: 'Control',
                    scope: '#/properties/more_data/properties/report_of_probate_court_file_number'
                  },
                  {
                    type: 'Label',
                    text: '@TODO UPLOAD'
                  }
                ],
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/more_data/properties/report_of_probate_court',
                    schema: {
                      const: true
                    }
                  }
                }
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Letzte Informationen',
      schema: erbausschlagungSchema,
      uischema: {
        ...getFormEndUiFull()
      }
    }
  ]
};
