import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import LoadingSpinner from './LoadingSpinner';
import Modal from './Modal';

interface IModalDelete {
  children?: React.ReactNode;
  title?: string;
  text?: string;
  abortBtnText?: string;
  deleteBtnText?: string;
  isLoading: boolean;
  close: () => void;
  handleDelete: () => void;
}

const ModalDelete: FC<PropsWithChildren<IModalDelete>> = ({
  close,
  title = 'Eintrag löschen',
  text = 'Wollen Sie wirklich den Eintrag unwiderruflich löschen?',
  isLoading = false,
  abortBtnText = 'Abbrechen',
  deleteBtnText = 'Löschen',
  handleDelete
}) => {
  return (
    <Modal
      title={title}
      close={close}
    >
      <div>
        {text}
      </div>
      <div className="btn-group">
        <button
          className="btn"
          onClick={close}
        >
          {abortBtnText}
        </button>
        <button
          className="btn btn-error"
          onClick={() => void handleDelete()}
          disabled={isLoading}
        >
          {isLoading && (
            <LoadingSpinner />
          )}
          {deleteBtnText}
        </button>
      </div>
    </Modal>
  );
};

export default ModalDelete;
