import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';
import { Tooltip } from 'react-tooltip';

import { sendSubmissionReminder } from '../../services/api/app';
import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner';

interface IReminderButton {
  uuid?: string;
  canBeReminded?: boolean;
  successFunction?: () => void;
}

const ReminderButton: FC<IReminderButton> = ({
  uuid,
  canBeReminded = false,
  successFunction
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const isDisabled = !canBeReminded || !uuid || isLoading;
  const handleReminder = async (): Promise<void> => {
    setIsLoading(true);

    const data = await sendSubmissionReminder({ uuid });

    setIsLoading(false);

    if (data.ok) {
      if (successFunction) {
        void successFunction();
      }
    }
  };

  return (
    <>
      <button
        onClick={() => void handleReminder()}
        className={classNames('btn btn-small', {
          'btn-disabled': isDisabled
        })}
        data-tooltip-id={isDisabled ? 'reminder-button-tooltip' : undefined}
        disabled={isDisabled}
      >
        {isLoading ? <LoadingSpinner /> : <Icon iconName="BellAlertIcon" />} Mandaten erinnern
      </button>
      {isDisabled && (
        <Tooltip
          id="reminder-button-tooltip"
          content="Der Mandant wurde bereits innerhalb der letzten 24 Stunden erinnert."
        />
      )}
    </>
  );
};

export default ReminderButton;
