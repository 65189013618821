import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

interface IAlert {
  message?: string;
  success?: boolean;
  className?: string;
}

const Alert: FC<PropsWithChildren<IAlert>> = ({
  children,
  message,
  className,
  success = false
}) => {
  const alertInnerHtml = children || message;

  return (
    <div className={classNames('p-4 border', className, {
      'bg-error-light border-error': !success,
      'bg-success-light border-success': success
    })}>
      {alertInnerHtml}
    </div>
  );
};

export default Alert;
