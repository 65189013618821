// eslint-disable-next-line import/no-extraneous-dependencies
// @ts-ignore djaklsdjklajdkla
// import html2pdf from 'html2pdf.js';
import { isString, map, mapValues, size, sortBy, toInteger } from 'lodash';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ReminderButton from '../component/actions/ReminderButton';
import CommentBox from '../component/Comment';
import FormHelper from '../component/FormHelper';
import Icon from '../component/Icon';
import Modal from '../component/Modal';
import AddCommentModal from '../component/modal/AddCommentModal';
import { APP_ROUTES, FORM_STATUS_ID, ICON_SIZE } from '../constants';
import { erbausschlagungConfig } from '../forms/erbausschlagung/config';
import { getSubmissionAgency } from '../services/api/app';
import type { TComment, TSubmission } from '../types/user';

const FormAgencyPage: FC = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ reload, setReload ] = useState(true);
  const [ addCommentFormId, setAddCommentFormId ] = useState<undefined | string | boolean>(false);
  const [ form, setForm ] = useState<TSubmission>();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [ feedbackAllowed, setFeedbackAllowed ] = useState(false);

  const printPage = (): void => {
    toast.error('Drucken wird noch nicht unterstützt x');
  };

  useEffect(() => {
    const getUsersData = async (): Promise<void> => {
      if (uuid) {
        const {
          submission: fetchedForm
        } = await getSubmissionAgency(uuid);

        setForm(fetchedForm);
        setFeedbackAllowed(toInteger(fetchedForm?.status) === FORM_STATUS_ID.READY_FOR_FEEDBACK);
        setIsLoading(false);
        setReload(false);
      }
    };

    if (uuid && reload) {
      void getUsersData();
    }

    return () => {
      // @TODO cancel token
    };
  }, [ uuid, reload ]);

  const groupedComments = useMemo(() => {
    if (!form || !form.comments) {
      return {};
    }

    const { comments } = form;

    const groupedByDate: { [key: string]: TComment[] } = {};

    comments.forEach(comment => {
      const dateKey = comment.day_key;

      if (dateKey) {
        groupedByDate[dateKey] = groupedByDate[dateKey] || [];
        groupedByDate[dateKey].push(comment);
      }
    });

    return mapValues(groupedByDate, group =>
      sortBy(group, comment => {
        return comment.created_at || 0;
      })
    );
  }, [ form ]);

  const config = erbausschlagungConfig;
  const steps = config.steps;

  if (!uuid) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  if (!isLoading && !form) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  return (
    <>
      <div className="dashboard-navi">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center gap-2"
        >
          <Icon
            size={ICON_SIZE.S}
            iconName="ArrowLeftIcon"
          />
          Zurück
        </button>
      </div>
      <div className="dashboard-head">
        <div className="flex gap-4 items-center">
          <div>
            <h1>{form?.title || 'Formular'}</h1>
          </div>
          <div className="flex gap-4 ml-auto">
            {feedbackAllowed ? (
              <>
                <button
                  onClick={() => setAddCommentFormId(form?.uuid)}
                  className="btn btn-error btn-small"
                >
                  Formular korrigieren
                </button>
                <button
                  // @TODO implement finalize function
                  className="btn btn-success btn-small"
                >
                  <Icon iconName="CheckIcon" /> Formular freigeben
                </button>
              </>
            ) : (
              <ReminderButton
                canBeReminded={form?.can_be_reminded}
                successFunction={() => setReload(true)}
                uuid={form?.uuid}
              />
            )}
            <button
              // @TODO implement print function
              onClick={printPage}
              className="btn btn-small"
            >
              Drucken
            </button>
          </div>
        </div>
      </div>
      <div className="flex xs-max:flex-col gap-4 w-full">
        {isLoading && (<div>Loading</div>)}
        {!isLoading && form && (
          <>
            <div className="bg-base-5 p-6 w-full flex flex-col gap-12 admin-form-view">
              {form.data ? map(steps, (step, index) => (
                <div key={index + step.title} className="bg-white p-6">
                  <h2 className="my-3">
                    <span>{index + 1}.</span>{step.title}
                  </h2>
                  <FormHelper
                    schema={step.schema}
                    uischema={step.uischema}
                    formData={form.data}
                    showValidation
                    readonly
                  />
                </div>
              )) : (
                <div className="bg-white p-6">
                  Formular ist noch leer
                </div>
              )}
            </div>
            {size(groupedComments) > 0 && (
              <div className="xs-max:w-full w-2/4 xs-max:order-first">
                <h2>Kommentare</h2>
                <div className="flex flex-col gap-4">
                  {map(groupedComments, (group, date) => (
                    <div className="bg-base-5 p-4">
                      <h3>{date}</h3>
                      {size(group) > 0 && (
                        <div className="flex flex-col gap-4">
                          {map(group, commentItem => (
                            <CommentBox
                              key={commentItem.id + commentItem.created_at}
                              author={commentItem.author}
                              text={commentItem.text}
                              created_at={commentItem.time_formatted}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!!addCommentFormId && isString(addCommentFormId) && (
        <Modal
          close={() => {
            setAddCommentFormId(false);
            setReload(true);
          }}
        >
          <AddCommentModal
            formId={addCommentFormId}
            successFunction={() => {
              setAddCommentFormId(false);
              setReload(true);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FormAgencyPage;
