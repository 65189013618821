import { compact, isString, size, times } from 'lodash';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import CardSkeleton from '../component/CardSkeleton';
import CardSubmission from '../component/CardSubmission';
import Icon from '../component/Icon';
import Modal from '../component/Modal';
import AddSubmissionModal from '../component/modal/AddSubmissionModal';
import AddUserModal from '../component/modal/AddUserModal';
import ModalDelete from '../component/ModalDelete';
import SkeletonText from '../component/SkeletonText';
import { APP_ROUTES, ICON_SIZE, ICON_TYPE, SKELETON_HEIGHT, SKELETON_WIDTH } from '../constants';
import { deleteUser, getFormsAgency, getUserAgency } from '../services/api/app';
import type { TSubmission } from '../types/user';

const UserAgencyPage: FC = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ reload, setReload ] = useState(true);
  const [ showUserEditModal, setShowUserEditModal ] = useState(false);
  const [ showUserDeleteModal, setShowUserDeleteModal ] = useState(false);
  const [ showModalNewSubmission, setShowModalNewSubmission ] = useState<boolean | string>(false);
  const [ user, setUser ] = useState<any>();
  const [ forms, setForms ] = useState<any>();
  const { uuid } = useParams();

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (uuid && reload) {
        setIsLoading(true);
        setReload(false);
        setUser(undefined);

        const { user: fetchedUser } = await getUserAgency(uuid);
        const { formTypes } = await getFormsAgency();

        if (fetchedUser) {
          setUser(fetchedUser);
          setForms(formTypes);
        }
        setIsLoading(false);
      }
    };

    if (uuid) {
      void getData();
    }

    return () => {
      // Anything in here is fired on component unmount. das
    };
  }, [ reload, uuid ]);

  if (!uuid) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  if (!isLoading && !user) {
    return <Navigate to={APP_ROUTES.HOME} replace />;
  }

  const handleDeleteFunc = async (): Promise<void> => {
    if (!isLoading && user) {
      setIsLoading(true);

      const data = await deleteUser({
        uuid: user.uuid
      });

      setIsLoading(false);

      if (data.ok) {
        setShowUserDeleteModal(false);
        setReload(true);
      }
    }
  };

  const userAddress = compact([
    user?.street_number,
    user?.zip,
    user?.city
  ]).join(', ');

  return (
    <>
      <div className="dashboard-navi">
        <Link to={APP_ROUTES.HOME} className="inline-flex items-center gap-2">
          <Icon
            size={ICON_SIZE.S}
            iconName="ArrowLeftIcon"
          />
          Zurück
        </Link>
        <div className="ml-auto flex gap-6">
          <button
            onClick={() => setShowUserEditModal(true)}
            className="inline-flex items-center gap-2"
            disabled={isLoading}
          >
            <Icon
              size={ICON_SIZE.S}
              iconName="PencilIcon"
            />
            Mandanten bearbeiten
          </button>
          <button
            className="inline-flex items-center gap-2"
            onClick={() => setShowUserDeleteModal(true)}
            disabled={isLoading}
          >
            <Icon
              size={ICON_SIZE.S}
              iconName="TrashIcon"
            />
            Mandanten löschen
          </button>
        </div>
      </div>
      <div className="dashboard-head">
        {isLoading ? (
          <div>
            <h1><SkeletonText width={SKELETON_WIDTH.L} height={SKELETON_HEIGHT.L} /></h1>
            <div className="py-4 flex gap-6 text-xs text-base-80">
              {times(3, index => (
                <div key={index}><SkeletonText /></div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h1>{user?.fullname}</h1>
            <div className="py-4 flex gap-6 text-xs text-base-80">
              <div>{userAddress}</div>
              <div>{user?.phone}</div>
              <div>{user?.email}</div>
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-4">
        <div className="w-8/12">
          <div className="flex-table submission-table">
            <div className="thead">
              <div className="tr">
                <div>Name</div>
                <div>Status</div>
                <div>Deadline</div>
              </div>
            </div>
            <div className="tbody">
              {isLoading && (<>
                {times(5, index => (
                  <CardSkeleton key={index} />
                ))}
              </>)}
              {!isLoading && (
                <>
                  {size(user?.submissions) > 0 ? (
                    <>
                      {user.submissions.map((submission: TSubmission) => {
                        const {
                          uuid: submissionUuid,
                          title,
                          status_name,
                          form_type_name,
                          status,
                          due_at
                        } = submission;

                        return (
                          <CardSubmission
                            key={submissionUuid}
                            uuid={submissionUuid}
                            title={title}
                            name={form_type_name}
                            statusName={status_name}
                            dueAt={due_at}
                            status={status}
                            reload={setReload}
                            isAdmin
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div className="no-result">Keine Formulare</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-4/12">
          <div className="flex-table small">
            <div className="thead">
              <div className="tr">
                <div>Formulare</div>
              </div>
            </div>
            <div className="tbody">
              {isLoading ? (
                <>
                  {times(5, index => (
                    <div key={index} className="tr"><SkeletonText /></div>
                  ))}
                </>
              ) : (
                <>
                  {Object.keys(forms).length && Object.keys(forms).map((formKey: string) => (
                    <div
                      key={formKey}
                      onClick={() => setShowModalNewSubmission(formKey)}
                    >
                      <div className="tr cursor-pointer">
                        {forms[formKey]}
                        <Icon
                          className="ml-auto"
                          iconName="PlusCircleIcon"
                          size={ICON_SIZE.S}
                          iconStyle={ICON_TYPE.OUTLINE}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!showModalNewSubmission && isString(showModalNewSubmission) && user?.uuid && (
        <Modal
          title={`Neues "${forms[showModalNewSubmission]}" Formular zuweisen`}
          close={() => {
            setShowModalNewSubmission(false);
            setReload(true);
          }}
        >
          <AddSubmissionModal
            formId={showModalNewSubmission}
            userUuid={user?.uuid}
            successFunction={() => {
              setReload(true);
              setShowModalNewSubmission(false);
            }}
          />
        </Modal>
      )}
      {showUserEditModal && (
        <Modal
          title="Mandanten bearbeiten"
          close={() => {
            setReload(true);
            setShowUserEditModal(false);
          }}
        >
          <AddUserModal
            user={user}
            successFunction={() => {
              setReload(true);
              setShowUserEditModal(false);
            }}
          />
        </Modal>
      )}
      {showUserDeleteModal && (
        <ModalDelete
          title="Mandanten löschen"
          text={`Wollen Sie wirklich den Mandanten "${user?.fullname}" unwiderruflich löschen?`}
          close={() => {
            setReload(true);
            setShowUserDeleteModal(false);
          }}
          handleDelete={() => {
            void handleDeleteFunc();
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default UserAgencyPage;
