export const getPersonUi = (type = ''): any => {
  const typeToUse = type ? `${type}/properties/` : '';
  const scope = `#/properties/${typeToUse}`;

  return [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: `${scope}title`
        },
        {
          type: 'Control',
          scope: `${scope}firstname`
        },
        {
          type: 'Control',
          scope: `${scope}lastname`
        }
      ]
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          scope: `${scope}birth_lastname`
        },
        {
          type: 'Control',
          scope: `${scope}date_of_birth`
        }
      ]
    }
  ];
};
